import React from "react";

import { withStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { red } from '@mui/material/colors';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import { styled } from '@mui/material/styles';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import commitment from "./images/CCA_Commitment-icon.png";
import fluency from "./images/CCA_Fluency-icon.png";
import listening from "./images/CCA_Listening-icon.png";
import mindset from "./images/CCA_Mindset-icon.png";
import risk from "./images/CCA_Risk-icon.png";
import stress from "./images/CCA_Stress-icon.png";
import verbal from "./images/CCA_Verbal-icon.png";

require("firebase/firestore");
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));


export default function MediaCard(props) {

  const { onClick,
    imageHeight,
    showDelete,
    showAvatar,
    avatarText,
    cardheight,
    ressource,
    categoryName,
    close,
    isCategories,
    maxLenght,
    openModalEdit } = props;

  const cardContentStyle = {
    height: cardheight, // Hauteur maximale souhaitée pour le contenu des cartes
    overflow: 'hidden',
  };

  const handleClickDelete = () => {
    props.handleDelete(props.ressource);
  };

  const handleEdit = () => {
    props.openModalEdit(props.ressource);
  };


  /*const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
    paddingTop: '0%', // Ratio d'aspect de 16:9 (modifier en fonction de vos besoins)
    backgroundSize: 'contain',
  }));*/

  const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
    paddingTop: '0%', // Utilisez un ratio d'aspect 1:1 pour maintenir la même taille du conteneur
    backgroundSize: 'contain',
    position: 'relative',
  }));

  const getDescription = () => {
    if (props.description) {
      if (props.description.length > maxLenght)
        return props.description.substring(0, maxLenght) + "..."
      else
        return props.description
    }
  }



  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };





  return (
    <div style={{ margin: '10%' }} key={props.title}>
      <Card sx={{ maxWidth: 100, height: '180px' }} key={props.title} >

        {(showAvatar || showDelete) && (
        <div style={{ display: 'flex', flexDirection: 'column', padding: '16px' }}>
        {/* Conteneur des avatars */}
        {showAvatar && (
          <div style={{ display: 'flex', gap: '0px', alignItems: 'center', marginBottom: '8px' }}>
            {avatarText.includes("1") && (
              <Tooltip title="Pillar : Mindset" placement="top">
                <Avatar aria-label="Pillar" src={mindset} />
              </Tooltip>
            )}
            {avatarText.includes("2") && (
              <Tooltip title="Pillar : Fluency" placement="top">
                <Avatar aria-label="Pillar" src={fluency} />
              </Tooltip>
            )}
            {avatarText.includes("3") && (
              <Tooltip title="Pillar : Stress Management" placement="top">
                <Avatar aria-label="Pillar" src={stress} />
              </Tooltip>
            )}
            {avatarText.includes("4") && (
              <Tooltip title="Pillar : Active Listening" placement="top">
                <Avatar aria-label="Pillar" src={listening} />
              </Tooltip>
            )}
            {avatarText.includes("5") && (
              <Tooltip title="Pillar : Non-Verbal Communication" placement="top">
                <Avatar aria-label="Pillar" src={verbal} />
              </Tooltip>
            )}
            {avatarText.includes("6") && (
              <Tooltip title="Pillar : Risk Taking" placement="top">
                <Avatar aria-label="Pillar" src={risk} />
              </Tooltip>
            )}
            {avatarText.includes("7") && (
              <Tooltip title="Pillar : Commitment" placement="top">
                <Avatar aria-label="Pillar" src={commitment} />
              </Tooltip>
            )}
          </div>
        )}
      
        {/* Boutons Delete et Edit */}
        {showDelete && (
          <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', marginTop: '8px' }}>
            <IconButton aria-label="Delete" onClick={handleClickDelete}>
              <DeleteIcon />
            </IconButton>
            <IconButton aria-label="Edit" onClick={handleEdit}>
              <ModeEditIcon />
            </IconButton>
          </div>
        )}
      </div>
      
       
       
       )}
        <CardActionArea onClick={onClick}>
          <StyledCardMedia
            component="img"
            image={props.urlImage}
            alt={props.title}
            style={{ objectFit: 'cover', width: '100%', height: '100%' }}
          />
          <CardContent style={cardContentStyle}>
            <Typography gutterBottom variant="h5" component="div">
              {props.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {getDescription()}
            </Typography>
          </CardContent>
        </CardActionArea>
        {!isCategories &&
          <CardActions disableSpacing>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
              disabled={ressource && !(ressource.description.length > 100)}
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>}

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>
              {ressource && ressource.description}
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
}